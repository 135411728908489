import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../../components/SEO/seo';
import Layout from '../../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Kwetsbaarheden melden",
  "path": "/nl/responsable-disclosure-policy"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Kwetsbaarheden melden`}</h1>
    <p>{`De bescherming van het geld en de gegevens van onze gebruikers is onze topprioriteit. Maar toch kan het zijn dat je een fout vindt in onze beveiliging. Je kunt ons helpen door deze kwetsbaarheid te melden.`}</p>
    <p>{`Meld een kwetsbaarheid altijd zo snel mogelijk en vraag ons om toestemming voordat je
de kwetsbaarheid openbaar maakt. Het openbaar maken van de kwetsbaarheid, voordat wij een oplossing hebben gevonden, kan ernstige gevolgen hebben voor onze klanten. Daarom werken wij graag eerst samen aan een oplossing.`}</p>
    <hr></hr>
    <h2>{`Een kwetsbaarheid melden`}</h2>
    <p>{`Deel de gevonden fout direct met ons op `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:hi@flowyour.money"
        }}>{`hi@flowyour.money`}</a></strong>{`. Het team zal deze inbox in de gaten houden en zal snel reageren. Voeg bij het melden de volgende informatie toe, zodat wij het direct op kunnen pakken:`}</p>
    <ul>
      <li parentName="ul">{`Ernst van de fout`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Is het Informatief / Laag / Gemiddeld / Hoog / Kritisch? Je kunt de `}<a parentName="em" {...{
                "href": "https://www.first.org/cvss/calculator/3.0"
              }}>{`CVSS berekening`}</a>{` gebruiken om de impact te beoordelen.`}</em></li>
        </ul>
      </li>
      <li parentName="ul">{`Leg uit welke kwetsbaarheid je hebt gevonden en geef ons voldoende
informatie om het probleem te reproduceren en te onderzoeken, bijvoorbeeld:`}
        <pre parentName="li"><code parentName="pre" {...{}}>{`- Screenshot / Proof of Concept
- App versie
`}</code></pre>
      </li>
    </ul>
    <aside>
    <div className="aside-icon">📩</div> Neem contact met ons op via <a href="mailto:hi@flowyour.money">hi@flowyour.money</a>
    </aside>
    <h2>{`De spelregels`}</h2>
    <p>{`Het kan zijn dat je illegale activiteiten hebt moeten uitvoeren om een kwetsbaarheid te ontdekken. Wij zullen deze activiteiten niet melden of een schadevergoeding eisen wanneer je je aan deze regels hebt gehouden:`}</p>
    <ul>
      <li parentName="ul">{`handel verantwoordelijk met de kennis over de kwetsbaarheid, en voer geen
handelingen die verder gaan dan wat nodig is om de fout aan te tonen;`}</li>
      <li parentName="ul">{`deel de toegang niet met anderen;`}</li>
      <li parentName="ul">{`veroorzaak geen schade;`}</li>
      <li parentName="ul">{`maak geen gebruik van een denial-of-service aanval of social engineering;`}</li>
      <li parentName="ul">{`zorg ervoor dat je onderzoek niet leidt tot een onderbreking van onze diensten;`}</li>
      <li parentName="ul">{`jouw onderzoek mag er nooit toe leiden dat bank- en/of klantgegevens openbaar worden;`}</li>
      <li parentName="ul">{`plaats nooit een achterdeur, ook niet om een kwetsbaarheid aan te tonen`}</li>
      <li parentName="ul">{`wijzig of verwijder nooit gegevens. Als je gegevens moet kopiëren, kopieer dan nooit meer gegevens dan strikt noodzakelijk;`}</li>
      <li parentName="ul">{`breng geen wijzigingen aan in het systeem;`}</li>
      <li parentName="ul">{`probeer niet vaker dan nodig in een systeem binnen te dringen;`}</li>
      <li parentName="ul">{`gebruik geen brute kracht technieken;`}</li>
      <li parentName="ul">{`gebruik geen technieken die de beschikbaarheid van onze diensten kunnen beïnvloeden;`}</li>
      <li parentName="ul">{`houd altijd rekening met de geldende wetten en voorschriften, want je kunt nog steeds in de problemen komen met de wet, zelfs als we je niet aangeven bij de autoriteiten.`}</li>
    </ul>
    <h3>{`Wat gebeurt er als ik een kwetsbaarheid meld?`}</h3>
    <p>{`Direct na ontvangst van de melding starten wij een onderzoek. We komen altijd binnen enkele dagen terug op je melding en houden je op de hoogte van onze vorderingen bij het oplossen van het probleem. De tijd die we nodig hebben om een probleem op te lossen is afhankelijk van de complexiteit. Nadat je een probleem hebt gemeld, vragen we je om het niet openbaar te maken om ons de tijd te geven om het probleem eerst op te lossen. Wij behandelen de ontvangen meldingen vertrouwelijk. Wij zullen je persoonlijke gegevens niet zonder jouw toestemming aan derden doorgeven, tenzij de wet of een gerechtelijk bevel ons hiertoe verplicht.`}</p>
    <h3>{`Kan ik een beloning krijgen voor het melden van een probleem?`}</h3>
    <p>{`Als dank voor je hulp kunnen wij je een beloning aanbieden, maar wij zijn nooit verplicht om een
beloning aan te bieden. Wij bieden alleen beloningen aan voor gebreken die ons op het moment van de melding niet bekend waren. Wij zullen het type en de grootte van de beloning bepalen op basis van het gemelde probleem, rekening houdend met de ernst van het probleem (onder andere). In het geval dat meerdere mensen hetzelfde probleem melden, zullen we alleen een beloning geven aan de eerste melder. Wij kunnen je een gratis versie van Flow+ for life geven (Life Time Deal).`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      